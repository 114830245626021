import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Table from '../../components/Table';
import Cell from '../../components/Table/cell';
import MonthYearSelect from '../../components/MonthYearSelect';
import { getEmoriRelationHealthPoint } from '../../apis/emori';
import { checkGroup } from '../../helpers/emori';

const useStyles = makeStyles(theme => ({
  root: {},
  topic: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '10px',
  },
  dashboard: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

export default ({ room, count }) => {
  const classes = useStyles();
  const { month, year } = useSelector(state => state.emori);
  const [ healthPoint, setHealthPoint ] = React.useState('');
  const [ info, setInfo ] = React.useState('');

  React.useEffect(() => {
    getHealthPoint();
  }, [month, year]);

  const getHealthPoint = async () => {
    try {
      const res = await getEmoriRelationHealthPoint(room.id, month, year);
      setHealthPoint(res[Object.keys(res)]);
      const group = checkGroup(res[Object.keys(res)]);
      setInfo(group);
    } catch (e) {}
  }

  return (
    <>
      {
        info && (
          <>
            <Grid container className={classes.topic}>
              <Grid item>
                <Typography variant='subtitle1' component='p' style={{ color: 'black', fontSize: '13px' }}>อารมณ์เฉลี่ยของห้อง</Typography>
              </Grid>
              <Grid item>
                <MonthYearSelect />
              </Grid>
            </Grid>
            <Grid container className={classes.dashboard}>
              <Grid item>
                <div style={{ position: 'relative', textAlign: 'center' }}>
                  <img src={require(`../../assets/images/bg_percent.svg`)} alt='percent'/>
                  <div
                    style={{
                      position: 'absolute',
                      top: info.percentage == 0 ? '35' : !Number.isInteger(info.percentage) ? '31%' : '30%',
                      left: info.percentage == 0 ? '37%' : !Number.isInteger(info.percentage) ? '19%' : info.percentage == 100 ? '27%' : '33%',
                      fontWeight: 'bold',
                      fontSize: '40px',
                      color: 'white'
                    }}
                  >
                    {info.percentage}%
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      top: '58%',
                      left: (info.feeling) === 'ปานกลาง' ? '35%' : '47%',
                      color: 'white',
                      fontSize: '15px'
                    }}
                  >
                    {info.feeling}
                  </div>
                </div>
              </Grid>
              <Grid item>
                <Grid container direction='column' spacing={1}>
                  <Grid item style={{ backgroundColor: '#20A0EF', borderRadius: '15px', margin: '2px'}}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <Cell align='center' style={{ minWidth: '100px', padding: 0 }}>
                            <Typography variant='subtitle1' component='p' style={{ color: 'white', fontSize: '12px' }}>จำนวนคนในห้อง</Typography>
                          </Cell>
                        </TableRow>
                        <TableRow>
                          <Cell align='center' style={{ minWidth: '100px', padding: 0 }}>
                            <Typography variant='subtitle1' component='p' style={{ color: 'white', fontSize: '16px' }}>{count}</Typography>
                          </Cell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  {/* in the future phase */}
                  {/* <Grid item style={{ backgroundColor: '#00BEFF', borderRadius: '15px', margin: '2px'}}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <Cell align='center' style={{ minWidth: '100px', padding: 0 }}>
                            <Typography variant='subtitle1' component='p' style={{ color: 'white', fontSize: '12px' }}>ทำแบบประเมินเพิ่ม</Typography>
                          </Cell>
                        </TableRow>
                        <TableRow>
                          <Cell align='center' style={{ minWidth: '100px', padding: 0 }}>
                            <Typography variant='subtitle1' component='p' style={{ color: 'white', fontSize: '16px' }}>4</Typography>
                          </Cell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item style={{ backgroundColor: '#DEF2FF', borderRadius: '15px', margin: '2px'}}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <Cell align='center' style={{ minWidth: '100px', padding: 0 }}>
                            <Typography variant='subtitle1' component='p' style={{ color: 'black', fontSize: '12px' }}>ปรึกษาแพทย์</Typography>
                          </Cell>
                        </TableRow>
                        <TableRow>
                          <Cell align='center' style={{ minWidth: '100px', padding: 0 }}>
                            <Typography variant='subtitle1' component='p' style={{ color: 'black', fontSize: '16px' }}>1</Typography>
                          </Cell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
    </>
  );
};
