import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { InputBase, Button, Paper, Grid, IconButton, Avatar, Badge, Divider, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SearchMember from '../../components/SearchMember';
import { AvatarGroup } from '@material-ui/lab';
import { getEmoriRelationAverage, getEmoriRelationProfileList, getEmoriRoomRelationAverage } from '../../apis/emori';
import NotificationEmptyState from '../../components/NotificationEmptyState';
import EmoriRelationDashboard from '../../components/EmoriRelationDashboard';
import EmoriRelationGraph from '../../components/EmoriRelationGraph';
import { createLoadingSelector } from '../../reducers/api';
import ManageMemberEmori from '../../components/ManageMemberEmori';
import { setEmoriRelationSearch } from '../../actions/emori';

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  graph:{
    margin: '10px',
  },
}));

export default (props) => {
  const { room } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const isLoading = useSelector(createLoadingSelector(['address/post']));
  const { month, year, search } = useSelector(state => state.emori);
  const [ searchText, setSearchText ] = React.useState('');
  const [ members, setMembers ] = React.useState('');
  const [ member, setMember ] = React.useState([]);
  const [ emoriList, setEmoriList] = React.useState('');
  const [ count, setCount ] = React.useState('');

  React.useEffect(() => {
    getProfile();
  }, [month, year, searchText]);

  const getProfile = async () => {
    try {
      const members = await getEmoriRelationProfileList(room.id, 4);
      const res = await getEmoriRoomRelationAverage(room.id, month, year);
      await setEmoriList(res.emori_percentages);
      setMember(members.results);
      setCount(members.count);
      if (searchText) {
        const res = await getEmoriRelationProfileList(room.id, 20, searchText);
        await setMembers(res.results);
        dispatch(setEmoriRelationSearch(searchText));
      }
    } catch (e) {}
  }

  const handleAddMember = async () => {
    const corporate = room?.owner.corporate.id;
    const roomId = room?.id;
    await navigate(`/emori/relation/profileMember?corporate=${corporate}&room=${roomId}`)
  }

  const handleClickMember = async (profileId) => {
    await navigate(`/emori/relation/chooseEmori?profileId=${profileId}`)
  }

  if (!room) return false;

  return (
    <>
      <SearchMember placeholder={'ค้นหาชื่อ'} onSearch={setSearchText} filter={'manage'} room={room}/>
      {
        searchText && members ? ( <ManageMemberEmori search={search} members={members} /> )
        : (
          <>
            <div className={classes.root}>
              <AvatarGroup max={5}>
                {
                  member.map((val, index) => {
                    return (
                      <Avatar className={classes.large} alt={val.name} src={val.image} onClick={() => handleClickMember(val.id)}/> // image
                    )
                  })
                }
                <Badge
                  overlap='circular'
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={<SmallAvatar alt='Remy Sharp' src={require(`../../assets/images/addNewCard.svg`)} />}
                >
                  <Avatar className={classes.large} alt='Add member' src={require(`../../assets/images/add_member.png`)} onClick={() => handleAddMember()}/>
                </Badge>
              </AvatarGroup>
            </div>
            <Divider style={{ backgroundColor: 'black' }}/>
            {
              room && count && (
                <EmoriRelationDashboard room={room} count={count} />
              )
            }
            <Typography variant='subtitle1' component='p' style={{ color: 'black', fontSize: '13px', marginTop: '10px' }}>อันดับอารมณ์</Typography>
            {
              emoriList ? (
                <div className={classes.graph}>
                  <EmoriRelationGraph emoji={emoriList} />
                </div>
              ) : (
                <NotificationEmptyState compact>
                  ไม่มีรายงานภาพรวมของเดือนนี้
                </NotificationEmptyState>
              )
            }
          </>
        )
      }
    </>
  );
};
