import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../../components/v2/Layout';
import { CardContent } from '@material-ui/core';
import EmoriRoom from '../../../components/EmoriRoom';
import { getUrlParams } from '../../../helpers/location';
import RegisterBusiness from '../../../components/RegisterBusiness';
import { createEmoriRoomByIdSelector } from '../../../reducers/emori';
import { getEmoriList, setEmoriRelationCorporate, setEmoriRelationRoom, getEmoriRoomProfileList } from '../../../actions/emori';

const useStyles = makeStyles(theme => ({
  root: {},
}));

export default ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = getUrlParams(location.search);
  const { id } = params;
  const room = useSelector(state => createEmoriRoomByIdSelector(state, id));

  React.useEffect(() => {
    dispatch(setEmoriRelationCorporate(room.owner.corporate.id));
    dispatch(getEmoriRoomProfileList(id, '', 0, 0));
    dispatch(setEmoriRelationRoom(id));
    dispatch(getEmoriList());
  }, []);

  return (
    <Layout bg='bg4' title={room ? room.name : ''}>
      <EmoriRoom room={room} />
    </Layout>
  );
};
