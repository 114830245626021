import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import NotificationEmptyState from '../../components/NotificationEmptyState';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: '100%',
    display: 'inline-block',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '0 10px 5px 11px',
  },
  circle: {
    width: '100%',
    height: '100px',
    background: 'linear-gradient(91deg, rgba(255, 255, 255, 0.77) 0%, rgba(170, 204, 255, 0.79) 100%)',
    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    // border: '1px solid',
    display: 'flex',
    justifyContent: 'center',

    position: 'relative',
    textAlign: 'center',
  },
  emori: {
    width: '66%',
  },
  tableCell: {
    maxWidth: '100%',
    marginBottom: '10px',
    padding: 0,
    border: 0,
  },
  emoriTextMedal: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    tableLayout: 'fixed',
  },
}))

export default ({ emoji }) => {
  const classes = useStyles();
  const { emori, month, year } = useSelector(state => state.emori);
  const [ emoriList, setEmoriList] = React.useState('');
  const [ orderEmori, setOrderEmori] = React.useState(null);

  React.useEffect(() => {
    emoriOrder();
  }, [month, year]);

  const emoriOrder = async () => { 
    await setOrderEmori(null);
    await setEmoriList(emoji);
    const orderedKeys = Object.keys(emoji).sort((a, b) => emoji[b].percentage - emoji[a].percentage);
    const myList = orderedKeys.length > 5 ? orderedKeys.splice(5) : orderedKeys;
    setOrderEmori(orderedKeys);
  };

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
 }

  if (!emoji) return false;

  return (
    <>
      {
        !emoji && (
          <NotificationEmptyState compact>
            ไม่มีรายงานภาพรวมของเดือนนี้
          </NotificationEmptyState>
        )
      }
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>
                {orderEmori && orderEmori.length === 1 || orderEmori && orderEmori.length === 2 ?
                  <div>
                    <div className={classes.circle}>
                      <img className={classes.emori} src={orderEmori && emori[parseInt(orderEmori[0])-1].image} alt='first_place' />
                      <img style={{ position: 'absolute', top: '75%', left: '60%' }} src={require(`../../assets/images/gold_medal.svg`)}/>
                    </div>
                    <div className={classes.emoriTextMedal}>
                      <p style={{width: '79px', height: '26px', background: '#24A9FB', boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>{orderEmori && emori[parseInt(orderEmori[0])-1].name}</p>
                    </div>
                    <div style={{ position: 'relative', textAlign: 'center' }}>
                      <img src={require(`../../assets/images/gold_place.svg`)} alt='gold'/>
                      <div style={{ position: 'absolute', top: '20%', left: '42%', 'fontWeight': 'bold', fontSize: '37px', color: 'white' }}>1</div>
                      <div style={{ position: 'absolute', top: '50%', left: isInt(orderEmori && emoji && emoji[orderEmori[0]].percentage) ? '32%' : '24%', color: 'white', fontSize: '20px', fontWeight: 'bold' }}>{orderEmori && emoji && emoji[orderEmori[0]].percentage}%</div>
                    </div>
                  </div>
                : orderEmori && orderEmori.length >= 3 ?
                  <div style={{ paddingTop: '50px' }}>
                    <div className={classes.circle}>
                      <img className={classes.emori} src={orderEmori && emori[parseInt(orderEmori[1])-1].image} alt='second_place' />
                      <img style={{ position: 'absolute', top: '75%', left: '60%' }} src={require(`../../assets/images/silver_medal.svg`)}/>
                    </div>
                    <div className={classes.emoriTextMedal}>
                      <p style={{width: '79px', height: '26px', background: '#C6EAF6', boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#23A1EF'}}>{orderEmori && emori[parseInt(orderEmori[1])-1].name}</p>
                    </div>
                    <div style={{ position: 'relative', textAlign: 'center' }}>
                      <img src={require(`../../assets/images/silver_place.svg`)} alt='silver'/>
                      <div style={{ position: 'absolute', top: '20%', left: '42%', 'fontWeight': 'bold', fontSize: '37px', color: 'white' }}>2</div>
                      <div style={{ position: 'absolute', top: '55%', left: isInt(orderEmori && emoji && emoji[orderEmori[1]].percentage) ? '39%' : '30%', color: 'white', fontSize: '15px' }}>{orderEmori && emoji && emoji[orderEmori[1]].percentage}%</div>
                    </div>
                  </div>
                : <></>}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {orderEmori && orderEmori.length === 2 ?
                  <div style={{ paddingTop: '50px' }}>
                    <div className={classes.circle}>
                      <img className={classes.emori} src={orderEmori && emori[parseInt(orderEmori[1])-1].image} alt='second_place' />
                      <img style={{ position: 'absolute', top: '75%', left: '60%' }} src={require(`../../assets/images/silver_medal.svg`)}/>
                    </div>
                    <div className={classes.emoriTextMedal}>
                      <p style={{width: '79px', height: '26px', background: '#C6EAF6', boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#23A1EF'}}>{orderEmori && emori[parseInt(orderEmori[1])-1].name}</p>
                    </div>
                    <div style={{ position: 'relative', textAlign: 'center' }}>
                      <img src={require(`../../assets/images/silver_place.svg`)} alt='silver'/>
                      <div style={{ position: 'absolute', top: '20%', left: '42%', 'fontWeight': 'bold', fontSize: '37px', color: 'white' }}>2</div>
                      <div style={{ position: 'absolute', top: '55%', left: isInt(orderEmori && emoji && emoji[orderEmori[1]].percentage) ? '39%' : '30%', color: 'white', fontSize: '15px' }}>{orderEmori && emoji && emoji[orderEmori[1]].percentage}%</div>
                    </div>
                  </div>
                : orderEmori && orderEmori.length >= 3 ?
                  <div>
                    <div className={classes.circle}>
                      <img className={classes.emori} src={orderEmori && emori[parseInt(orderEmori[0])-1].image} alt='first_place' />
                      <img style={{ position: 'absolute', top: '75%', left: '60%' }} src={require(`../../assets/images/gold_medal.svg`)}/>
                      
                    </div>
                    <div className={classes.emoriTextMedal}>
                      <p style={{width: '79px', height: '26px', background: '#24A9FB', boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>{orderEmori && emori[parseInt(orderEmori[0])-1].name}</p>
                    </div>
                    <div style={{ position: 'relative', textAlign: 'center' }}>
                      <img src={require(`../../assets/images/gold_place.svg`)} alt='gold'/>
                      <div style={{ position: 'absolute', top: '20%', left: '42%', 'fontWeight': 'bold', fontSize: '37px', color: 'white' }}>1</div>
                      <div style={{ position: 'absolute', top: '50%', left: isInt(orderEmori && emoji && emoji[orderEmori[0]].percentage) ? '32%' : '24%', color: 'white', fontSize: '20px', fontWeight: 'bold' }}>{orderEmori && emoji && emoji[orderEmori[0]].percentage}%</div>
                    </div>
                  </div>
                :<></>}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {orderEmori && orderEmori.length >= 3 ?
                  <div style={{ paddingTop: '86px' }}>
                    <div className={classes.circle}>
                      <img className={classes.emori} src={orderEmori && emori[parseInt(orderEmori[2])-1].image} alt='third_place' />
                      <img style={{ position: 'absolute', top: '75%', left: '60%' }} src={require(`../../assets/images/bronze_medal.svg`)}/>
                    </div>
                    <div className={classes.emoriTextMedal}>
                      <p style={{width: '79px', height: '26px', background: '#C6EAF6', boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#23A1EF'}}>{orderEmori && emori[parseInt(orderEmori[2])-1].name}</p>
                    </div>
                    <div style={{ position: 'relative', textAlign: 'center' }}>
                      <img src={require(`../../assets/images/bronze_place.svg`)} alt='bronze'/>
                      <div style={{ position: 'absolute', top: '20%', left: '42%', 'fontWeight': 'bold', fontSize: '37px', color: 'white' }}>3</div>
                      <div style={{ position: 'absolute', top: '55%', left: isInt(orderEmori && emoji && emoji[orderEmori[2]].percentage) ? '39%' : '30%', color: 'white', fontSize: '15px' }}>{orderEmori && emoji && emoji[orderEmori[2]].percentage}%</div>
                    </div>
                  </div>
                : <></>}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};